import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import CustomTooltip, { Props as TooltipProps } from 'components/CustomTooltip';
import styles from './Hint.scss';

const theme = thematize(styles);

interface Props {
  id: string;
  text: ReactElement | string;
  placement?: TooltipProps['placement'];
}

const Hint = ({ text, placement, id }: Props): ReactElement => (
  <CustomTooltip id={id} message={text} placement={placement}>
    <span className={theme('container')}>
      <span className={theme('mark')}>?</span>
    </span>
  </CustomTooltip>
);

export default Hint;
