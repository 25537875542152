import { FC, ReactElement } from 'react';

import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import styles from './Collapsible.scss';

const theme = thematize(styles);

type Props = {
  className?: string;
  content?: ReactElement | string;
  expanded?: boolean;
};

const Collapsible: FC<Props> = ({ className = '', content, expanded = false }) => {
  if (!expanded || !content) {
    return null;
  }

  return <div className={`${theme('container', { expanded })} ${className}`}>{content}</div>;
};

type TogglerProps = {
  className?: string;
  expanded?: boolean;
  onClick?: () => void;
};

export const CollapsibleToggler: FC<TogglerProps> = ({
  expanded = false,
  onClick = undefined,
  className = '',
}) => (
  <Icon
    className={`${theme('toggler', { expanded })} ${className}`}
    type="arrowDown"
    onClick={onClick}
  />
);

export default Collapsible;
