import { ReactElement } from 'react';
import { noop } from 'lodash';

import { getProfileInitials } from 'lib/utils';
import Avatar from 'components/Base/Avatar';

interface Props {
  profile: AH$Profile | AH$MiniProfile | AH$DraggedProfile;
  className?: string;
  linkDisabled?: boolean;
  linkPath?: string;
  linkTarget?: '_self' | '_blank';
  onClick?: () => void;
  size?: 's' | 'm' | 'l';
  withoutPhoto?: boolean;
}

const ProfileAvatar = ({
  profile,
  linkPath = '',
  onClick = noop,
  size = 'm',
  className = '',
  linkDisabled = false,
  withoutPhoto = false,
  linkTarget = '_blank',
}: Props): ReactElement => {
  const initials = getProfileInitials(profile);
  const link = linkPath || `/profiles/${profile.id}/`;

  return (
    <Avatar
      id={profile.id}
      initials={initials}
      pictureSources={!withoutPhoto ? profile.pictures : []}
      linkPath={link}
      onClick={onClick}
      size={size}
      className={className}
      linkDisabled={linkDisabled}
      linkTarget={linkTarget}
    />
  );
};

export default ProfileAvatar;
