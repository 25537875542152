import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Dialog from 'components/Base/Dialog';
import Icon from 'components/Base/Icon';
import messages from './messages';
import styles from './DeleteDialog.scss';

const theme = thematize(styles);

export interface Props {
  title: ReactNode;
  cancelText?: ReactNode;
  content?: ReactNode;
  deleteText?: ReactNode;
  onCancel?: () => void;
  onDelete?: () => void;
}

const DeleteDialog = ({
  title,
  content,
  onDelete = noop,
  onCancel = noop,
  deleteText,
  cancelText,
}: Props): ReactElement => (
  <Dialog>
    <Dialog.Title>{title}</Dialog.Title>
    <div className={theme('dialog-body', { 'without-content': !content })}>{content}</div>
    <Dialog.Actions>
      <div className={theme('actions', { 'without-content': !content })}>
        <Button className={theme('actions__delete-btn')} color={COLORS.danger} onClick={onDelete}>
          {deleteText || (
            <>
              <span className={theme('actions__delete-icon')}>
                <Icon type="warning" style={{ width: '10px' }} />
              </span>
              <FormattedMessage {...messages.delete} />
            </>
          )}
        </Button>
        <Button appearance={APPEARANCES.outlined} onClick={onCancel}>
          {cancelText || <FormattedMessage {...messages.cancel} />}
        </Button>
      </div>
    </Dialog.Actions>
  </Dialog>
);

export default DeleteDialog;
