import { FC, useEffect, useState } from 'react';

let timeout: ReturnType<typeof setTimeout> | null = null;

const AnimatedEllipsis: FC = () => {
  const [dots, setDots] = useState(1);
  const text = dots === 0 ? '' : '.'.repeat(dots);

  useEffect(() => {
    timeout = setTimeout(() => {
      setDots(dots === 3 ? 0 : dots + 1);
    }, 350);
  }, [dots]);

  useEffect(
    () => () => {
      if (timeout) clearTimeout(timeout);
    },
    []
  );

  return <span>{text}</span>;
};

export default AnimatedEllipsis;
