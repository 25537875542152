import { Scrollbars } from '@amazing-hiring/react-custom-scrollbars';
import { ReactChild, ReactElement } from 'react';

import thematize from 'lib/thematize';
import { useSelector } from 'hooks';
import Text, { Is } from 'components/Base/Text';
import styles from './ContentLayout.scss';

const theme = thematize(styles);

const TITLE_HEIGHT = 88;

interface Props {
  content: ReactElement;
  disabledSide?: boolean;
  isForm?: boolean;
  side?: ReactElement;
  strippedSide?: boolean;
  title?: ReactChild;
}

const ContentLayout = ({
  content,
  side,
  title,
  disabledSide = false,
  isForm = false,
  strippedSide = false,
}: Props): ReactElement => {
  const bodyHeight = useSelector(({ app }) => app.bodyRect.height);
  const scrollbarHeight = window.innerHeight - bodyHeight;

  return (
    <div className={theme('container', { full: !side, form: isForm })}>
      {side ? (
        <div
          className={theme('side', { disabled: disabledSide })}
          style={{
            height: `calc(100vh - ${scrollbarHeight}px)`,
          }}
        >
          {title && (
            <div className={theme('side-title')} style={{ height: TITLE_HEIGHT }}>
              <Text is={Is.Header}>{title}</Text>
            </div>
          )}
          {strippedSide ? (
            side
          ) : (
            <div
              className={theme('side-scrollbars')}
              style={{
                height: `calc(100vh ${title ? `- ${TITLE_HEIGHT}px` : ''} - ${scrollbarHeight}px)`,
              }}
            >
              <Scrollbars>
                <div className={theme('side-content')}>{side}</div>
              </Scrollbars>
            </div>
          )}
        </div>
      ) : (
        title && (
          <div className={theme('title')}>
            <Text is={Is.Header}>{title}</Text>
          </div>
        )
      )}
      <div className={theme('main', { form: isForm, full: !side })}>{content}</div>
    </div>
  );
};

export default ContentLayout;
