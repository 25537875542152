import { CSSProperties, FC, MouseEvent, ReactElement } from 'react';

import thematize from 'lib/thematize';
import { calculateStyles, ParentCoordinatesType } from 'components/Base/Dropdown/utils';
import styles from './Body.scss';

const theme = thematize(styles);

type Props = {
  children: ReactElement;
  parentCoordinates: ParentCoordinatesType;
  show: boolean;
  bodyClassName?: string;
  bodyStyles?: CSSProperties;
  menuShouldBlockScroll?: boolean;
  minWidth100?: boolean;
  onAwayClick?: (e: MouseEvent<HTMLElement>) => void;
  placement?: 'left' | 'center' | 'right';
  size?: 's' | 'm';
  sticky?: boolean;
  textAlignCenter?: boolean;
};

const BodyContent: FC<Props> = ({
  children,
  show,
  onAwayClick,
  parentCoordinates,
  bodyClassName = '',
  placement = 'left',
  textAlignCenter = false,
  minWidth100 = false,
  menuShouldBlockScroll = false,
  sticky = false,
  size = 'm',
  bodyStyles = {},
}) => (
  <div>
    {menuShouldBlockScroll && show && <div className={theme('background')} onClick={onAwayClick} />}
    <div
      className={`${theme('body', { textAlignCenter, size })} ${bodyClassName}`}
      style={{
        ...calculateStyles(placement, minWidth100, parentCoordinates, sticky),
        ...bodyStyles,
      }}
    >
      {children}
    </div>
  </div>
);

export default BodyContent;
