import { ChangeEvent, FC } from 'react';
import { set } from 'lodash';

import thematize from 'lib/thematize';
import styles from './Switcher.scss';

const theme = thematize(styles);

type SwitcherProps = {
  checked: boolean;
  id: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  dataQa?: { 'data-qa': string } | null;
  disabled?: boolean;
};

const Switcher: FC<SwitcherProps> = ({
  id,
  onChange,
  checked,
  value,
  disabled = false,
  className = '',
  dataQa = null,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const type = checked ? 'checked' : 'blank';

    set(e, 'target.dataset.type', type);
    onChange(e);
  };

  return (
    <div className={`${theme('switcher')} ${className}`} {...dataQa}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={theme('switcher-label', { checked, disabled })}>
        <input
          type="checkbox"
          className={theme('switcher-input')}
          checked={checked}
          onChange={disabled ? undefined : handleChange}
          disabled={disabled}
          id={id}
          data-type={checked ? 'checked' : 'blank'}
          value={value}
        />
        <span className={theme('switcher-inner')} />
      </label>
    </div>
  );
};

export default Switcher;
