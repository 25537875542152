import { Component, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { size, truncate } from 'lodash';

import thematize from 'lib/thematize';
import messages from './messages';
import styles from './MoreLessText.scss';

const theme = thematize(styles);

interface Props {
  containerClassName: string;
  cutTextLength: number;
  isTriggerInline: boolean;
  showLessTrigger: string | ReactElement;
  showMoreTrigger: string | ReactElement;
  text: string;
  textClassName: string;
  triggerClassName: string;
}

interface State {
  isMoreShown: boolean;
}

class MoreLessText extends Component<Props, State> {
  state = {
    isMoreShown: false,
  };

  static defaultProps = {
    showMoreTrigger: <FormattedMessage {...messages.showMore} />,
    showLessTrigger: <FormattedMessage {...messages.showLess} />,
    cutTextLength: 80,
    containerClassName: '',
    textClassName: '',
    triggerClassName: '',
    isTriggerInline: false,
  };

  toggleMoreLess = (): void => {
    this.setState((prevState) => ({ isMoreShown: !prevState.isMoreShown }));
  };

  render(): ReactElement {
    const { isMoreShown } = this.state;
    const {
      text,
      showMoreTrigger,
      showLessTrigger,
      cutTextLength,
      containerClassName,
      textClassName,
      triggerClassName,
      isTriggerInline,
    } = this.props;
    const textToRender = isMoreShown ? text : truncate(text, { length: cutTextLength });
    const isTriggerShown = size(text) > cutTextLength;
    const trigger = isMoreShown ? showLessTrigger : showMoreTrigger;

    return (
      <div className={containerClassName}>
        <span className={textClassName}>{textToRender}</span>{' '}
        {isTriggerShown && (
          <div
            className={`${theme('trigger', { isTriggerInline })} ${triggerClassName}`}
            onClick={this.toggleMoreLess}
          >
            {trigger}
          </div>
        )}
      </div>
    );
  }
}

export default MoreLessText;
