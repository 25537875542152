import { ReactElement, ReactNode, useCallback } from 'react';
import moment, { Moment } from 'moment';
import Calendar from 'rc-calendar/lib/Calendar';
import ruLocale from 'rc-calendar/lib/locale/ru_RU';

import { useUser } from 'hooks';
import './DatePicker.scss';

interface Props {
  // this two props are not listed in the docs, found them in source code of rc-calendar.
  // value and onChange that are listed in the docs are smth like internal state and have
  // inappropriate behavior: onChange is fired on month change with value from this new month
  onSelect: (value: Moment) => void;
  selectedValue: Moment;
  className?: string;
  dateRender?: (current: Moment, selected: Moment) => ReactNode;
  disabledDate?: (cellValue: Moment) => boolean;
  pastDatesDisabled?: boolean;
  showToday?: boolean;
}

const DatePicker = ({
  disabledDate,
  pastDatesDisabled,
  className = '',
  showToday = false,
  ...rest
}: Props): ReactElement => {
  const {
    settings: { language },
  } = useUser();

  const disablePastDates = useCallback(
    (cellValue: Moment) => cellValue.isBefore(moment(), 'day') || disabledDate?.(cellValue),
    [disabledDate]
  );

  return (
    <Calendar
      showDateInput={false}
      disabledDate={pastDatesDisabled ? disablePastDates : disabledDate}
      className={`AH-calendar-customization ${className}`}
      showToday={showToday}
      locale={language === 'ru' ? ruLocale : undefined}
      mode="date"
      {...rest}
    />
  );
};

export default DatePicker;
