import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import styles from './Error.scss';

const theme = thematize(styles);

interface Props {
  error: string;
  className?: string;
  staticPosition?: boolean;
}

const Error = ({ error, className = '', staticPosition = false }: Props): ReactElement => (
  <div className={`${theme('container', { 'static-position': staticPosition })} ${className}`}>
    {error}
  </div>
);

export default Error;
