import { forwardRef, HTMLProps, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import { COLORS, SIZES } from 'components/Base/constants';
import styles from './Button.scss';

const theme = thematize(styles);

export const APPEARANCES = {
  filled: 'filled',
  text: 'text',
  outlined: 'outlined',
} as const;

interface CommonProps {
  appearance?: keyof typeof APPEARANCES;
  block?: boolean;
  children?: ReactNode | string;
  className?: string;
  color?: keyof typeof COLORS;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  size?: keyof typeof SIZES;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
}

export type Props = CommonProps & Omit<HTMLProps<HTMLButtonElement>, keyof CommonProps>;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      color = COLORS.default,
      size = SIZES.m,
      appearance = APPEARANCES.filled,
      block = false,
      disabled = false,
      loading = false,
      className = '',
      onClick = noop,
      to,
      ...rest
    },
    ref
  ) => {
    const commonProps = {
      className: `${theme('button', {
        color,
        size,
        appearance,
        block,
        loading,
        disabled,
      })} ${className}`,
      onClick: disabled || loading ? noop : onClick,
    };

    return to ? (
      <Link to={to} {...commonProps}>
        {children}
      </Link>
    ) : (
      <button {...commonProps} type="button" ref={ref} disabled={disabled} {...rest}>
        {children}
      </button>
    );
  }
);

export default Button;

export { COLORS, SIZES };
