export default {
  ok: {
    id: 'dialog.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'dialog.cancel',
    defaultMessage: 'Cancel',
  },
  no: {
    id: 'dialog.no',
    defaultMessage: 'No',
  },
};
