import {
  CSSProperties,
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import BodyContent from 'components/Base/Dropdown/BodyContent';
import { ParentCoordinatesType } from './utils';
import animation from './animation.scss';
import styles from './Body.scss';

const theme = thematize(styles);

type OptionProps = {
  children?: ReactNode | string;
  internalOnOptionClick?: (textValue: string) => void;
  onMouseDown?: (e: MouseEvent<HTMLElement>) => void;
  onOptionClick?: (textValue: string, e: MouseEvent<HTMLElement>) => void;
  optionClassName?: string;
  size?: 's' | 'm';
  style?: CSSProperties;
  textValue?: string;
};

const Option: FC<OptionProps> = ({
  children,
  onOptionClick = noop,
  optionClassName = '',
  textValue = '',
  internalOnOptionClick = noop,
  size = 'm',
  style,
  onMouseDown,
}) => {
  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      onOptionClick(textValue, e);
      internalOnOptionClick(textValue);
    },
    [onOptionClick, internalOnOptionClick, textValue]
  );

  return (
    <div
      className={`${theme('option', { size })} ${optionClassName}`}
      style={style}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};

interface Props {
  children: ReactElement;
  onAwayClick: (e: MouseEvent<HTMLElement>) => void;
  parentCoordinates: ParentCoordinatesType;
  show: boolean;
  bodyClassName?: string;
  bodyStyles?: CSSProperties;
  disableAnimation?: boolean;
  menuShouldBlockScroll?: boolean;
  minWidth100?: boolean;
  placement?: 'left' | 'center' | 'right';
  setBodyRef?: (ref: HTMLElement) => void;
  size?: 's' | 'm';
  sticky?: boolean;
  textAlignCenter?: boolean;
}

const Body: FC<Props> & { Option: FC<OptionProps> } = ({
  children,
  parentCoordinates,
  show,
  setBodyRef,
  onAwayClick,
  bodyClassName = '',
  placement = 'left',
  minWidth100 = false,
  textAlignCenter = false,
  disableAnimation = false,
  menuShouldBlockScroll = false,
  sticky = false,
  size = 'm',
  bodyStyles = {},
}) => {
  const popup = useRef<HTMLDivElement>();

  const body = (
    <BodyContent
      parentCoordinates={parentCoordinates}
      show={show}
      onAwayClick={onAwayClick}
      bodyClassName={bodyClassName}
      placement={placement}
      minWidth100={minWidth100}
      textAlignCenter={textAlignCenter}
      menuShouldBlockScroll={menuShouldBlockScroll}
      sticky={sticky}
      size={size}
      bodyStyles={bodyStyles}
    >
      {children}
    </BodyContent>
  );
  const bodyWithDisabledAnimation = show ? body : <span />;
  const bodyToRender = disableAnimation ? (
    bodyWithDisabledAnimation
  ) : (
    <CSSTransition in={show} timeout={150} classNames={animation} mountOnEnter unmountOnExit>
      {body}
    </CSSTransition>
  );

  useEffect(() => {
    popup.current = document.createElement('div');
    if (document.body && popup.current) {
      document.body.appendChild(popup.current);
    }

    setBodyRef?.(popup.current);

    return (): void => {
      if (document.body && popup.current) {
        document.body.removeChild(popup.current);
      }
    };
  }, [setBodyRef]);

  if (sticky) return popup.current ? bodyToRender : null;

  return popup.current ? ReactDOM.createPortal(bodyToRender, popup.current) : null;
};

Body.Option = Option;

export default Body;
