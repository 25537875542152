import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import Button, { APPEARANCES } from 'components/Base/Button';
import { COLORS } from 'components/Base/constants';
import Dialog from 'components/Base/Dialog';
import messages from './messages';
import styles from './ConfirmDialog.scss';

const theme = thematize(styles);

export interface Props {
  title: ReactElement | string;
  cancelText?: ReactElement | string;
  classNames?: {
    buttons?: string;
    content?: string;
    title?: string;
  };
  confirmText?: ReactElement | string;
  content?: ReactElement | string | null;
  declineText?: ReactElement | string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onDecline?: () => void;
}

const ConfirmDialog = ({
  title,
  content,
  onConfirm = noop,
  onCancel = noop,
  onDecline,
  confirmText,
  cancelText,
  declineText,
  classNames,
}: Props): ReactElement => (
  <Dialog>
    <Dialog.Title>
      <div className={classNames?.title || ''}>{title}</div>
    </Dialog.Title>
    {content && (
      <Dialog.Content>
        <div className={classNames?.content || ''}>{content}</div>
      </Dialog.Content>
    )}
    <Dialog.Actions>
      <div className={`${theme('actions', { wide: !!onDecline })} ${classNames?.buttons || ''}`}>
        {onDecline && (
          <Button appearance={APPEARANCES.outlined} onClick={onDecline}>
            {declineText || <FormattedMessage {...messages.no} />}
          </Button>
        )}
        <div>
          <Button appearance={APPEARANCES.outlined} onClick={onCancel}>
            {cancelText || <FormattedMessage {...messages.cancel} />}
          </Button>
          <Button color={COLORS.primary} className={theme('ok')} onClick={onConfirm}>
            {confirmText || <FormattedMessage {...messages.ok} />}
          </Button>
        </div>
      </div>
    </Dialog.Actions>
  </Dialog>
);

export default ConfirmDialog;
