import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import styles from './index.scss';

const theme = thematize(styles);

interface Props {
  className?: string;
}

const Hr = ({ className }: Props): ReactElement => (
  <hr className={`${theme('container')} ${className}`} />
);

export default Hr;
