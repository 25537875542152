import { forwardRef, InputHTMLAttributes, KeyboardEvent, useCallback } from 'react';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import iconsBase from 'components/Base/Icon/iconsBase';
import Error from './Error';
import styles from './Input.scss';

const theme = thematize(styles);

interface CommonProps {
  appearance?: 'bordered' | 'lined';
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  error?: string;
  errorClassName?: string;
  errorStaticPosition?: boolean;
  icon?: keyof typeof iconsBase;
  id?: string;
  invalid?: boolean;
  onPressEnter?: () => void;
  size?: 's' | 'm' | 'l';
  value?: string | number;
}

export type HTMLInputOmitedProps = Omit<InputHTMLAttributes<HTMLInputElement>, keyof CommonProps>;

export type Props = CommonProps & HTMLInputOmitedProps;

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      disabled = false,
      size = 'm',
      appearance = 'bordered',
      value,
      onPressEnter = noop,
      invalid = false,
      error = '',
      errorStaticPosition = false,
      className = '',
      errorClassName = '',
      icon,
      containerClassName,
      ...rest
    }: Props,
    ref
  ) => {
    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onPressEnter();
        }
      },
      [onPressEnter]
    );

    return (
      <div className={`${theme('container', { icon: !!icon })} ${containerClassName}`}>
        {icon && <Icon type={icon} className={theme('icon')} />}
        <input
          ref={ref}
          id={id}
          name={id}
          className={`${theme('input', {
            appearance,
            size,
            invalid: !!(invalid || error),
            type: rest.type,
            icon: !!icon,
          })} ${className}`}
          onKeyDown={handleKeyDown}
          type="text"
          disabled={disabled}
          value={value}
          autoComplete="off"
          {...rest}
        />
        {error && (
          <Error className={errorClassName} error={error} staticPosition={errorStaticPosition} />
        )}
      </div>
    );
  }
);

export default Input;
