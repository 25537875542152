import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import Button from 'components/Base/Button';
import Icon from 'components/Base/Icon';
import styles from './Table.scss';

const theme = thematize(styles);

export enum Order {
  Up = 'sortUp',
  Down = 'sortDown',
  Default = 'sort',
}

interface SortProps {
  [key: string]: any;
  order?: Order;
}

const Sort = ({ order = Order.Default, ...props }: SortProps): ReactElement => (
  <Button appearance="text" size="s" {...props}>
    <span key={order}>
      <Icon type={order} className={theme('icon', { order })} />
    </span>
  </Button>
);

export default Sort;
