import { DetailedHTMLProps, ReactElement, TextareaHTMLAttributes } from 'react';

import thematize from 'lib/thematize';
import Error from 'components/Base/Input/Error';
import styles from './Textarea.scss';

const theme = thematize(styles);

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  className?: string;
  error?: string;
  errorClassName?: string;
  invalid?: boolean;
  value?: string;
};

const Textarea = <P extends Props>({
  invalid = false,
  error = '',
  className = '',
  errorClassName = '',
  value,
  ...rest
}: P): ReactElement => (
  <div className={theme('container')}>
    <textarea
      className={`${theme('textarea', {
        invalid: !!(invalid || error),
      })} ${className}`}
      value={value}
      {...rest}
    />
    {error && <Error className={errorClassName} error={error} />}
  </div>
);

export default Textarea;
