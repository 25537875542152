import { FC, ReactElement, ReactNode, useState } from 'react';

import thematize from 'lib/thematize';
import Dropdown from 'components/Base/Dropdown';
import Icon from 'components/Base/Icon';
import { FiltersListChips } from 'components/FilterItems/FiltersListChips';
import { getIsChecked } from 'components/Filters/lib';
import styles from './FilterSelect.scss';

const theme = thematize(styles);

type FilterSelectProps = {
  checkedFilters: Set<string>;
  children: ReactNode;
  facetId: string;
  filters: Array<AH$FilterValue>;
  hasBetaBadge: (id: string) => boolean;
  onChange: ({
    target: {
      checked,
      value,
      dataset: { type },
    },
  }: {
    target: {
      checked: boolean;
      dataset: {
        type?: string;
      };
      value: string;
    };
  }) => void;
  excludedFilters?: Set<string>;
  getCustomCheckedIds?: AH$FilterConfig['getCustomCheckedIds'];
  getCustomLabels?: {
    exclusions?: ({ id }: { id: string }) => ReactElement | null;
  };
};

export const FilterSelect: FC<FilterSelectProps> = ({
  children,
  checkedFilters,
  excludedFilters,
  filters,
  getCustomCheckedIds,
  getCustomLabels,
  onChange,
  hasBetaBadge,
  facetId,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      optionsOpened={open}
      onButtonClick={() => setOpen(!open)}
      onFocusLoss={() => setOpen(false)}
      sticky
      closeOnAwayClick
      disableAnimation
      button={
        <div className={theme('input-wrapper')}>
          <Icon type="angleRight" className={theme('icon', { open })} />
          <div className={theme('input')}>
            {checkedFilters.size ? (
              <FiltersListChips
                filters={filters.filter((filter) =>
                  getIsChecked(checkedFilters, filter.id, getCustomCheckedIds)
                )}
                checkedFilters={checkedFilters}
                facetId={facetId}
                excludedFilters={excludedFilters}
                getCustomCheckedIds={getCustomCheckedIds}
                getCustomLabels={getCustomLabels}
                onChange={onChange}
                hasBetaBadge={hasBetaBadge}
              />
            ) : null}
          </div>
        </div>
      }
      bodyClassName={theme('list')}
      placement="center"
    >
      {children}
    </Dropdown>
  );
};
