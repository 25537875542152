import { FC, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './Dialog.scss';

const theme = thematize(styles);

type ComponentType = FC<{ children: ReactNode; className?: string }>;

interface DialogType extends ComponentType {
  Actions: ComponentType;
  Content: ComponentType;
  Title: ComponentType;
}

const Title: ComponentType = ({ children, className = '' }) => (
  <div className={`${theme('title')} ${className}`}>{children}</div>
);

const Content: ComponentType = ({ children, className = '' }) => (
  <div className={`${theme('content')} ${className}`}>{children}</div>
);

const Actions: ComponentType = ({ children, className = '' }) => (
  <div className={`${theme('actions')} ${className}`}>{children}</div>
);

const Dialog: DialogType = ({ children, className = '' }) => (
  <div className={`${theme('container')} ${className}`}>{children}</div>
);

Dialog.Title = Title;
Dialog.Content = Content;
Dialog.Actions = Actions;

export default Dialog;
