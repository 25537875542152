import { ReactElement, useRef } from 'react';
import { connect } from 'react-redux';
import { noop, toInteger, uniqueId, values } from 'lodash';

import thematize from 'lib/thematize';
import { State } from 'stores/ReduxStore';
import Dropdown from 'components/Base/Dropdown';
import Input from 'components/Base/Input';
import styles from './TimePicker.scss';

const INTERVALS = {
  AM: 'AM',
  PM: 'PM',
};
const theme = thematize(styles);

export const generateMinutesRange = () => {
  const minutesRange = [];

  for (let i = 0; i < 6; ++i) {
    minutesRange.push(`${i * 10}`);
  }
  minutesRange[0] = '00';

  return minutesRange;
};

export const generateHoursRange = ({
  min = 0,
  max = 23,
  formatted = true,
}: {
  formatted?: boolean;
  max?: number;
  min?: number;
} = {}) => {
  const hoursRange = [];

  for (let i = min; i < max + 1; ++i) {
    hoursRange.push(formatted ? `0${i}`.slice(-2) : i);
  }

  return hoursRange;
};

export const generateEnHoursRange = (currentHours: number | string, plusMinus: string) => {
  const hoursRange: Array<number> = new Array(24)
    .fill(0, 0, 24)
    .map((val, i) => i)
    .filter((val) => val - +plusMinus >= 0 && val + +plusMinus <= 23);

  return hoursRange
    .filter((val) => (currentHours > 12 ? val > 12 : val < 12))
    .map((val) => val % 12 || 12)
    .sort((a, b) => +a - +b);
};

const defaultMinutesRange = generateMinutesRange();
const defaultHoursRange = generateHoursRange();

interface WrapperProps {
  hours: number | string;
  minutes: number | string;
  onHoursChange: (value: string) => void;
  onMinutesChange: (value: string) => void;
  hoursRange?: Array<string | number>;
  interval?: string;
  minutesRange?: Array<string>;
  onIntervalChange?: (value: $Values<typeof INTERVALS>) => void;
  plusMinus?: string;
  readOnly?: boolean;
}

interface Props {
  hours?: number | string;
  hoursRange?: Array<string | number>;
  interval?: string;
  minutes?: number | string;
  minutesRange?: Array<string>;
  onHoursChange?: (value: string) => void;
  onIntervalChange?: (value: $Values<typeof INTERVALS>) => void;
  onMinutesChange?: (value: string) => void;
  plusMinus?: string;
  readOnly?: boolean;
}

const TimePicker = ({
  hours,
  minutes,
  interval,
  readOnly = false,
  hoursRange = defaultHoursRange,
  minutesRange = defaultMinutesRange,
  onHoursChange = noop,
  onMinutesChange = noop,
  onIntervalChange = noop,
}: Props): ReactElement => {
  const id = useRef(uniqueId());

  return (
    <>
      <Dropdown
        button={
          <Input
            id={`${id.current}-hours`}
            appearance="lined"
            value={hours}
            readOnly={readOnly}
            onFocus={(e) => e.target.blur()}
            onChange={noop}
          />
        }
        containerClassName={theme('dropdown-container')}
        bodyClassName={theme('body')}
        placement="center"
        textAlignCenter
        disabled={readOnly}
      >
        {hoursRange.map((hour) => (
          <Dropdown.Option key={hour} textValue={hour as string} onOptionClick={onHoursChange} />
        ))}
      </Dropdown>
      :
      <Dropdown
        button={
          <Input
            id={`${id.current}-minutes`}
            appearance="lined"
            value={minutes}
            readOnly={readOnly}
            onFocus={(e) => e.target.blur()}
            onChange={noop}
          />
        }
        containerClassName={theme('dropdown-container')}
        bodyClassName={theme('body')}
        placement="center"
        textAlignCenter
        disabled={readOnly}
      >
        {minutesRange.map((minutes) => (
          <Dropdown.Option key={minutes} textValue={minutes} onOptionClick={onMinutesChange} />
        ))}
      </Dropdown>
      {interval && (
        <Dropdown
          button={
            <Input
              className={theme('interval')}
              id={`${id.current}-interval`}
              appearance="lined"
              value={interval}
              readOnly={readOnly}
              onFocus={(e) => e.target.blur()}
              onChange={noop}
            />
          }
          containerClassName={theme('dropdown-container')}
          bodyClassName={theme('body')}
          placement="center"
          textAlignCenter
          disabled={readOnly}
        >
          {values(INTERVALS).map((interval) => (
            <Dropdown.Option key={interval} textValue={interval} onOptionClick={onIntervalChange} />
          ))}
        </Dropdown>
      )}
    </>
  );
};

export default connect(({ app }: State, props: WrapperProps) => {
  const { language } = app.user.settings;

  if (language === 'en') {
    const hours24 = toInteger(props.hours);
    const plusMinus = { props };
    const hours12 = hours24 % 12 || 12;
    const interval = hours24 < 12 ? INTERVALS.AM : INTERVALS.PM;
    const hoursRange = generateEnHoursRange(props.hours, props.plusMinus as string);

    const convertTo24 = (hours: string | number, interval: $Values<typeof INTERVALS>) => {
      const hours12 = toInteger(hours);

      if (interval === INTERVALS.AM && hours12 === 12) {
        return '00';
      }
      if (interval === INTERVALS.PM && hours12 !== 12) {
        return `0${hours12 + 12}`.slice(-2);
      }
      return `0${hours12}`.slice(-2);
    };

    const onIntervalChange = (interval: $Values<typeof INTERVALS>): void => {
      const hours24 = convertTo24(hours12, interval);

      props.onHoursChange(hours24);
    };
    const onHoursChange = (hours12: string): void => {
      const hours24 = convertTo24(hours12, interval);

      props.onHoursChange(hours24);
    };

    if (hours24 - +plusMinus < 0 || hours24 + +plusMinus > 23)
      return {
        interval,
        hoursRange,
        hours:
          hours24 - +plusMinus < 0 || hours24 + +plusMinus > 23
            ? hoursRange[0]
            : hours12.toString(),
        onHoursChange,
        onIntervalChange,
      };
  }
  return {};
})(TimePicker);
