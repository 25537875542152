export default {
  showMore: {
    id: 'base.showMore',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'base.showLess',
    defaultMessage: 'Show less',
  },
};
