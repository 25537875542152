import { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import thematize from 'lib/thematize';
import styles from './Link.scss';

const theme = thematize(styles);

interface Props
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  'data-qa'?: string;
}

const Link: FC<Props> = ({ children, className, ...props }) => (
  <a
    className={`${theme('link')} ${className || ''}`}
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </a>
);

export default Link;
