import { ReactElement, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './Box.scss';

const theme = thematize(styles);

export enum Is {
  Blank = 'blank',
  Outlined = 'outlined',
}

interface Props {
  children: ReactNode;
  className?: string;
  is?: Is;
}

const Box = ({ children, is = Is.Blank, className = '' }: Props): ReactElement => (
  <div className={`${theme('container', { is })} ${className}`}>{children}</div>
);

export default Box;
