import { FC } from 'react';

import dataTest from 'lib/data-test';
import thematize from 'lib/thematize';
import styles from './Spinner.scss';

const theme = thematize(styles);

interface Props {
  className?: string;
  show?: boolean;
}

const Spinner: FC<Props> = ({ show = true, className = '' }) => (
  <div className={`${theme('container', { hidden: !show })} ${className}`} {...dataTest('spinner')}>
    <div className={theme('spinner')} />
  </div>
);

export default Spinner;
