import {
  cloneElement,
  createContext,
  createRef,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { isString, noop } from 'lodash';

import thematize from 'lib/thematize';
import { mergeRefs } from 'lib/utils';
import { useElementCoordinates, useFocusLoss } from 'hooks';
import Body from 'components/Base/Dropdown/Body';
import styles from './InputWithAutoComplete.scss';

const theme = thematize(styles);

interface InputWithAutoCompleteContextType {
  currentInputValue: string;
  displayAllOptions: boolean;
  internalOnOptionClick: (textValue: string) => void;
}

const InputWithAutoCompleteContext = createContext<InputWithAutoCompleteContextType>({
  internalOnOptionClick: noop,
  currentInputValue: '',
  displayAllOptions: false,
});

interface OptionProps {
  textValue: string;
  children?: ReactElement;
  onOptionClick?: (textValue: string) => void;
  optionClassName?: string;
}

const Option = ({
  children,
  onOptionClick = noop,
  optionClassName = '',
  textValue = '',
}: OptionProps): ReactElement => {
  const { currentInputValue, displayAllOptions, internalOnOptionClick } = useContext(
    InputWithAutoCompleteContext
  );

  const highlightedTextValue =
    textValue.indexOf(currentInputValue) === 0 && !!currentInputValue ? (
      <span>
        <b>{currentInputValue}</b>
        {textValue.slice(currentInputValue.length)}
      </span>
    ) : (
      textValue
    );

  const returnOptionClassName = `${optionClassName} ${theme('non-highlighted-option', {
    hidden:
      !children &&
      !!currentInputValue &&
      !displayAllOptions &&
      textValue.indexOf(currentInputValue) !== 0,
  })}`;
  const itemToRender = children || highlightedTextValue;

  return (
    <Body.Option
      onOptionClick={onOptionClick}
      optionClassName={returnOptionClassName}
      textValue={textValue}
      internalOnOptionClick={internalOnOptionClick}
    >
      {itemToRender}
    </Body.Option>
  );
};

interface Props {
  input: ReactElement;
  bodyClassName?: string;
  children?: ReactNode;
  closeOnAwayClick?: boolean;
  containerClassName?: string;
  disableAnimation?: boolean;
  displayAllOptions?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  menuShouldBlockScroll?: boolean;
  minWidth100?: boolean;
  onChange?: (value: string) => void;
  onFocusLoss?: () => void;
  optionsOpened?: boolean;
  placement?: 'left' | 'right' | 'center';
  textAlignCenter?: boolean;
  value?: string;
}

const InputWithAutoComplete = ({
  children,
  input,
  onChange = noop,
  value,
  closeOnAwayClick = true,
  bodyClassName = '',
  displayAllOptions = false,
  optionsOpened,
  placement = 'left',
  minWidth100 = false,
  textAlignCenter = false,
  disableAnimation = false,
  containerClassName = '',
  onFocusLoss = noop,
  menuShouldBlockScroll = false,
  inputRef: propInputRef,
}: Props): ReactElement => {
  const [stateOpen, setStateOpen] = useState(false);
  const [stateValue, setStateValue] = useState<null | string>(null);
  const inputRef = createRef<HTMLInputElement>();
  const containerRef = createRef<HTMLDivElement>();
  const bodyRef = useRef(null);
  const setBodyRef = useCallback(
    (ref: HTMLElement) => {
      // @ts-ignore
      bodyRef.current = ref;
    },
    [bodyRef]
  );
  const onFocus = useCallback(() => {
    if (!input.props.readOnly) {
      setStateOpen(true);
    }
  }, [input.props.readOnly]);
  const resultOpened = typeof optionsOpened === 'boolean' ? optionsOpened : stateOpen;
  const defaultStateValue = stateValue === null ? input.props.defaultValue || '' : stateValue;
  const currentInputValue = isString(value) ? value : defaultStateValue;

  const handleAwayClick = useCallback(() => {
    if (closeOnAwayClick) {
      onFocusLoss();
      setStateOpen(false);
    }
  }, [setStateOpen, closeOnAwayClick, onFocusLoss]);

  useFocusLoss([containerRef, bodyRef], menuShouldBlockScroll ? noop : handleAwayClick);

  const internalOnChange = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;

      setStateValue(value);
      setStateOpen(true);
      onChange(value);
    },
    [setStateValue, setStateOpen, onChange]
  );

  const internalOnOptionClick = (textValue: string) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    setStateValue(textValue);
    onChange(textValue);
    setStateOpen(false);
  };

  const enhancedInput = cloneElement(input, {
    onChange: internalOnChange,
    value: currentInputValue,
    onFocus,
    onClick: onFocus,
    autoComplete: 'off',
    ref: propInputRef ? mergeRefs(inputRef, propInputRef) : inputRef,
    defaultValue: undefined,
  });

  const inputCoordinates = useElementCoordinates(inputRef);

  return (
    <div className={`${theme('container')} ${containerClassName}`} ref={containerRef}>
      {enhancedInput}
      {children && (
        <Body
          parentCoordinates={inputCoordinates}
          show={resultOpened}
          bodyClassName={bodyClassName}
          placement={placement}
          minWidth100={minWidth100}
          textAlignCenter={textAlignCenter}
          disableAnimation={disableAnimation}
          setBodyRef={setBodyRef}
          onAwayClick={handleAwayClick}
          menuShouldBlockScroll={menuShouldBlockScroll}
        >
          <InputWithAutoCompleteContext.Provider
            value={{
              internalOnOptionClick,
              currentInputValue,
              displayAllOptions,
            }}
          >
            {children}
          </InputWithAutoCompleteContext.Provider>
        </Body>
      )}
    </div>
  );
};

InputWithAutoComplete.Option = Option;

export default InputWithAutoComplete;
