import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps, HTMLAttributes, ReactElement } from 'react';

import dataTest from 'lib/data-test';
import iconsBase from 'components/Base/Icon/iconsBase';

type Props = Omit<ComponentProps<typeof FontAwesomeIcon>, 'icon'> & {
  className?: string;
  style?: HTMLAttributes<HTMLElement>['style'];
  type?: keyof typeof iconsBase;
};

const Icon = ({ type = 'default', className = '', ...rest }: Props): ReactElement => (
  <FontAwesomeIcon
    icon={iconsBase[type]}
    className={className}
    {...dataTest(`${type}Icon`)}
    {...rest}
  />
);

export default Icon;

export { iconsBase };
