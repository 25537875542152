export default {
  delete: {
    id: 'dialog.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'dialog.cancel',
    defaultMessage: 'Cancel',
  },
};
