import { DetailedHTMLProps, InputHTMLAttributes, ReactElement } from 'react';

import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import styles from './RadioInput.scss';

const theme = thematize(styles);

type Props = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'id'
> & {
  id: string;
  content?: string;
};

const RadioInput = <T extends Props>(props: T): ReactElement => {
  const { id, ...restProps } = props;

  return (
    <label htmlFor={id} className={theme('container')}>
      <input id={id} type="radio" className={theme('input')} {...restProps} />
      <span className={theme('icon_checked')}>
        <Icon type="radioCircle" />
      </span>
      <span className={`${theme('icon_unchecked')} fa-layers`}>
        <Icon type="outlinedCircle" />
        <Icon type="circle" transform="shrink-2" className={theme('blank')} />
      </span>
    </label>
  );
};

export default RadioInput;
