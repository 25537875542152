import { FC, ReactElement, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './AdditionalElements.scss';

const theme = thematize(styles);

export const Divider = (): ReactElement => <div className={theme('divider')} />;

type Props = {
  text: string | ReactNode;
  className?: string;
};

export const Header: FC<Props> = ({ text, className = '' }) => (
  <div className={`${theme('header')} ${className}`}>{text}</div>
);
