import { ReactElement, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './Text.scss';

const theme = thematize(styles);

export enum Is {
  Header = 'header',
  Strong = 'strong',
  Regular = 'regular',
}

interface CommonProps {
  className?: string;
  handleClick?: () => void;
  is?: Is;
}
interface WithValue {
  value: ReactNode;
  children?: never;
}
interface WithChildren {
  children: ReactNode;
  value?: never;
}
type Props = CommonProps & (WithChildren | WithValue);

const Text = ({
  children,
  value,
  is = Is.Regular,
  className = '',
  handleClick,
}: Props): ReactElement => (
  <span onClick={handleClick} className={`${className} ${theme('text', { is })}`}>
    {value || children}
  </span>
);

export default Text;
