export interface Styles {
  center?: number;
  left?: number;
  minWidth?: number;
  right?: number;
  top?: number;
  transform?: string;
}

export interface ParentCoordinatesType {
  height: number;
  left: number;
  top: number;
  width: number;
}

export const calculateStyles = (
  placement: string,
  minWidth100: boolean,
  parentCoordinates: ParentCoordinatesType,
  sticky = false
): Styles => {
  const styles: Styles = {};

  switch (placement) {
    case 'left': {
      styles.left = parentCoordinates.left;
      styles.top = parentCoordinates.top + parentCoordinates.height;
      break;
    }
    case 'right': {
      styles.left = parentCoordinates.left + parentCoordinates.width;
      styles.top = parentCoordinates.top + parentCoordinates.height;
      styles.transform = 'translateX(-100%)';
      break;
    }
    case 'center': {
      styles.left = parentCoordinates.left + parentCoordinates.width / 2;
      styles.top = parentCoordinates.top + parentCoordinates.height;
      styles.transform = 'translateX(-50%)';
      break;
    }
    default: {
      styles.left = 9999;
      styles.top = 9999;
    }
  }

  if (minWidth100) {
    styles.minWidth = parentCoordinates.width;
  }

  if (sticky) {
    switch (placement) {
      case 'right': {
        styles.left = parentCoordinates.width;
        break;
      }
      case 'center': {
        styles.left = parentCoordinates.width / 2;
        break;
      }
      case 'left': {
        styles.left = 0;
        break;
      }
      default: {
        styles.left = 9999;
        styles.top = 9999;
      }
    }
    styles.top = undefined;
  }

  return styles;
};
