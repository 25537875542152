import { ComponentProps, ReactElement } from 'react';
import { Virtuoso } from 'react-virtuoso';

const VirtualizedList = ({
  useWindowScroll = true,
  ...props
}: ComponentProps<typeof Virtuoso>): ReactElement => (
  <Virtuoso {...props} useWindowScroll={useWindowScroll} />
);

export default VirtualizedList;
