import { brands, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const iconsBase = {
  phone: solid('phone'),
  email: solid('at'),
  egg: solid('egg'),
  messengers: solid('comments'),
  link: solid('globe'),
  telegram: brands('telegram-plane'),
  telegramCircle: brands('telegram'),
  folder: solid('folder'),
  folderAlt: regular('folder'),
  comments: solid('pencil-alt'),
  message: solid('envelope'),
  resume: regular('address-card'),
  location: solid('map-marker-alt'),
  position: solid('building'),
  education: solid('graduation-cap'),
  star: solid('star'),
  starAlt: regular('star'),
  tag: solid('tag'),
  skype: brands('skype'),
  replied: solid('reply'),
  opened: solid('eye'),
  sent: regular('envelope'),
  pending: solid('spinner'),
  error: solid('exclamation'),
  warning: solid('exclamation-triangle'),
  draft: regular('file'),
  planned: regular('clock'),
  search: solid('search'),
  question: solid('question-circle'),
  questionAlt: regular('question-circle'),
  default: solid('user'),
  copy: regular('copy'),
  delete: solid('trash'),
  file: solid('paperclip'),
  courses: solid('graduation-cap'),
  contacts: solid('address-book'),
  reload: solid('sync'),
  reloadAlt: solid('sync-alt'),
  terminal: solid('terminal'),
  stepForward: solid('step-forward'),
  circle: solid('circle'),
  report: solid('exclamation-triangle'),
  edit: solid('pen'),
  penToSquare: solid('pen-to-square'),
  ai: solid('thumbs-up'),
  caretUp: solid('caret-up'),
  caretDown: solid('caret-down'),
  role: solid('user-tag'),
  activity: solid('clock'),
  joinDate: solid('plus-circle'),
  extension: brands('chrome'),
  cv: solid('file'),
  externalLink: solid('external-link-alt'),
  redo: solid('redo-alt'),
  ellipsis: solid('ellipsis-h'),
  cross: solid('times'),
  crossCircle: solid('times-circle'),
  crossCircleAlt: regular('times-circle'),
  check: solid('check'),
  checkCircle: solid('check-circle'),
  activeDays: solid('tachometer-alt'),
  aggregate: solid('sitemap'),
  bounce: solid('exclamation-circle'),
  square: solid('square'),
  blankSquare: regular('square'),
  checkedSquare: solid('check-square'),
  excludedSquare: solid('minus-square'),
  radioCircle: solid('dot-circle'),
  outlinedCircle: regular('circle'),
  history: solid('history'),
  openFolder: solid('folder-open'),
  private: solid('lock'),
  public: solid('lock-open'),
  download: solid('arrow-alt-circle-down'),
  image: solid('image'),
  alignLeft: solid('align-left'),
  alignRight: solid('align-right'),
  alignCenter: solid('align-center'),
  arrowDown: solid('chevron-down'),
  arrowUp: solid('chevron-up'),
  arrowRight: solid('arrow-right'),
  arrowLeft: solid('arrow-left'),
  arrowDownCircle: solid('chevron-circle-down'),
  arrowUpCircle: solid('chevron-circle-up'),
  arrowRightCircle: solid('chevron-circle-right'),
  chevronRight: solid('chevron-right'),
  archive: solid('archive'),
  profiles: solid('users'),
  intelligence: solid('chart-pie'),
  industry: solid('industry'),
  positionAlt: solid('briefcase'),
  info: solid('info-circle'),
  paint: solid('fill-drip'),
  heart: solid('heart'),
  fire: solid('fire-alt'),
  video: solid('video'),
  book: solid('book'),
  surprise: regular('surprise'),
  sort: solid('sort'),
  sortUp: solid('sort-up'),
  sortDown: solid('sort-down'),
  angleRight: solid('angle-right'),
  angleLeft: solid('angle-left'),
  angleDown: solid('angle-down'),
  googlePlus: brands('google-plus-g'),
  plus: solid('plus'),
  settings: solid('cog'),
  heading: solid('heading'),
  unorderedList: solid('list-ul'),
  orderedList: solid('list-ol'),
  bold: solid('bold'),
  italic: solid('italic'),
  removeFormat: solid('remove-format'),
  linkAlt: solid('link'),
  save: solid('save'),
  minimize: solid('window-minimize'),
  share: solid('share'),
  visible: solid('eye'),
  hidden: solid('eye-slash'),
  searchPlus: solid('search-plus'),
  dataEnrichment: solid('magic-wand-sparkles'),
  serviceOrders: regular('calendar-check'),
  ban: solid('ban'),
  bars: solid('bars'),
  rotateRight: solid('rotate-right'),
  xMark: solid('xmark'),
  arrowUpWideShort: solid('arrow-up-wide-short'),
  arrowDownWideShort: solid('arrow-down-wide-short'),
  magnifyingGlass: solid('magnifying-glass'),
  checkDouble: solid('check-double'),
  paperPlane: solid('paper-plane'),
} as const;

export default iconsBase;
