import {
  cloneElement,
  createContext,
  createRef,
  CSSProperties,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import { bodyScrollDisable, bodyScrollEnable } from 'lib/utils';
import { useElementCoordinates, useFocusLoss } from 'hooks';
import Body from 'components/Base/Dropdown/Body';
import { Divider, Header } from './AdditionalElements';
import styles from './Dropdown.scss';

const theme = thematize(styles);

interface DropdownContextType {
  internalOnOptionClick: (textValue?: string) => void;
  size: 's' | 'm';
}

const DropdownContext = createContext<DropdownContextType>({
  internalOnOptionClick: noop,
  size: 'm',
});

type OptionProps = {
  children?: ReactElement | ReactNode | string;
  closeOnClick?: boolean;
  onMouseDown?: (e: MouseEvent<HTMLElement>) => void;
  onOptionClick?: (textValue: string, e: MouseEvent<HTMLElement>) => void;
  optionClassName?: string;
  size?: 's' | 'm';
  style?: CSSProperties;
  textValue?: string;
};

const Option: FC<OptionProps> = ({
  children,
  onOptionClick = noop,
  optionClassName = '',
  textValue = '',
  style,
  size,
  onMouseDown,
  closeOnClick = true,
}) => {
  const { internalOnOptionClick, size: ctxSize } = useContext(DropdownContext);

  return (
    <Body.Option
      onOptionClick={onOptionClick}
      onMouseDown={onMouseDown}
      optionClassName={optionClassName}
      textValue={textValue}
      internalOnOptionClick={closeOnClick ? internalOnOptionClick : undefined}
      size={size || ctxSize}
      style={style}
    >
      {children || textValue}
    </Body.Option>
  );
};

export type GetButtonCoordinates = (coords: ReturnType<typeof useElementCoordinates>) => void;

export interface Props {
  button: ReactElement;
  bodyClassName?: string;
  bodyStyles?: CSSProperties;
  children?: ReactNode;
  closeOnAwayClick?: boolean;
  containerClassName?: string;
  containerInnerProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  disableAnimation?: boolean;
  disabled?: boolean;
  getButtonCoordinates?: GetButtonCoordinates;
  menuShouldBlockScroll?: boolean;
  minWidth100?: boolean;
  onButtonClick?: (e: MouseEvent<HTMLElement>) => void;
  onFocusLoss?: (e: MouseEvent<HTMLElement>) => void;
  optionsOpened?: boolean;
  placement?: 'left' | 'center' | 'right';
  size?: 's' | 'm';
  sticky?: boolean;
  textAlignCenter?: boolean;
  trigger?: 'click' | 'hover';
}

const Dropdown: FC<Props> & { Option: FC<OptionProps> } & { Divider: FC } & {
  Header: FC<{ text: string | ReactNode; className?: string }>;
} = ({
  children,
  button,
  onButtonClick = noop,
  closeOnAwayClick = true,
  bodyClassName = '',
  optionsOpened,
  placement = 'left',
  minWidth100 = false,
  textAlignCenter = false,
  disableAnimation = false,
  containerClassName = '',
  onFocusLoss = noop,
  disabled = false,
  menuShouldBlockScroll = false,
  sticky = false,
  containerInnerProps = {},
  bodyStyles = {},
  getButtonCoordinates,
  trigger = 'click',
  size = 'm',
}) => {
  const [stateOpen, setStateOpen] = useState(false);
  const resultOpened = typeof optionsOpened === 'boolean' ? optionsOpened : stateOpen;
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = createRef<HTMLElement>();
  const bodyRef = useRef<HTMLElement>(null);
  const setBodyRef = useCallback(
    (ref: HTMLElement) => {
      // @ts-ignore
      bodyRef.current = ref;
    },
    [bodyRef]
  );
  const handleAwayClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (closeOnAwayClick && resultOpened) {
        onFocusLoss(e);
        setStateOpen(false);
      }
      if (menuShouldBlockScroll) bodyScrollEnable();
    },
    [setStateOpen, closeOnAwayClick, onFocusLoss, resultOpened, menuShouldBlockScroll]
  );

  useFocusLoss([containerRef, bodyRef], menuShouldBlockScroll ? noop : handleAwayClick);

  const internalOnOptionClick = useCallback(() => {
    setStateOpen(false);
    if (menuShouldBlockScroll) bodyScrollEnable();
  }, [setStateOpen, menuShouldBlockScroll]);

  const internalOnButtonClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const open = !stateOpen && !disabled;

      if (menuShouldBlockScroll) {
        if (open) {
          bodyScrollDisable();
        } else {
          bodyScrollEnable();
        }
      }
      onButtonClick(e);
      if (trigger === 'click') {
        setStateOpen(open);
      }
    },
    [setStateOpen, stateOpen, disabled, menuShouldBlockScroll, onButtonClick, trigger]
  );

  const enhancedButton = cloneElement(button, {
    onClick: internalOnButtonClick,
    ref: buttonRef,
  });

  const buttonCoordinates = useElementCoordinates(buttonRef);

  type THoverToggleDropdown = (open: boolean) => (e: MouseEvent<HTMLDivElement>) => void;

  const hoverToggleDropdown: THoverToggleDropdown = (open) => () => {
    if (trigger === 'hover' && !disabled) {
      setStateOpen(open);
    }
  };

  useEffect(() => {
    getButtonCoordinates?.(buttonCoordinates);
  }, [buttonCoordinates, getButtonCoordinates]);

  useEffect(
    () => () => {
      if (menuShouldBlockScroll) {
        bodyScrollEnable();
      }
    },
    [menuShouldBlockScroll]
  );

  return (
    <div
      className={`${theme('container')} ${containerClassName}`}
      ref={containerRef}
      onMouseEnter={hoverToggleDropdown(true)}
      onMouseLeave={hoverToggleDropdown(false)}
      onClick={hoverToggleDropdown(false)}
      {...containerInnerProps}
    >
      {enhancedButton}
      <Body
        parentCoordinates={buttonCoordinates}
        show={resultOpened}
        bodyClassName={bodyClassName}
        placement={placement}
        minWidth100={minWidth100}
        textAlignCenter={textAlignCenter}
        disableAnimation={disableAnimation}
        setBodyRef={setBodyRef}
        onAwayClick={handleAwayClick}
        menuShouldBlockScroll={menuShouldBlockScroll}
        sticky={sticky}
        size={size}
        bodyStyles={bodyStyles}
      >
        <DropdownContext.Provider value={{ internalOnOptionClick, size }}>
          {children}
        </DropdownContext.Provider>
      </Body>
    </div>
  );
};

Dropdown.Option = Option;
Dropdown.Divider = Divider;
Dropdown.Header = Header;

export default Dropdown;
