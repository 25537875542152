export const COLORS: {
  danger: 'danger';
  default: 'default';
  grey: 'grey';
  orange: 'orange';
  primary: 'primary';
  secondary: 'secondary';
  success: 'success';
  warning: 'warning';
} = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
  success: 'success',
  orange: 'orange',
  grey: 'grey',
};

export const SIZES: {
  l: 'l';
  m: 'm';
  s: 's';
  xl: 'xl';
  xs: 'xs';
  xxl: 'xxl';
  xxs: 'xxs';
} = {
  xxs: 'xxs',
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
};
