import { ReactElement } from 'react';

import thematize from 'lib/thematize';
import styles from './Badge.scss';

const theme = thematize(styles);

interface Props {
  title: string;
  appearance?: 'primary' | 'warning';
  size?: 'small' | 'extra-small';
}

const Badge = ({ title, size, appearance }: Props): ReactElement => (
  <span className={theme('badge', { size, appearance })}>{title}</span>
);

export default Badge;
