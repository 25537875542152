import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import dataQa from 'lib/data-qa';
import thematize from 'lib/thematize';
import Icon from 'components/Base/Icon';
import { icons } from 'components/CustomCheckbox/CustomCheckbox';
import Tooltip from 'components/CustomTooltip';
import messages from 'components/TokenAppliedTerm/messages';
import styles from './Chip.scss';

const theme = thematize(styles);

const getDisplayedIcon = ({
  checked,
  combined,
  excluded,
}: {
  checked?: boolean;
  combined?: boolean;
  excluded?: boolean;
}): keyof typeof icons => {
  if (checked && excluded) return 'excluded';
  if (checked) return 'checked';
  if (combined) return 'combined';
  return 'blank';
};

type ChipProps = {
  checked: boolean;
  children: ReactNode | string;
  combined: boolean;
  excluded: boolean;
  id: string;
  onChange: ({
    target: {
      checked,
      value,
      dataset: { type },
    },
  }: {
    target: {
      checked: boolean;
      dataset: {
        type?: string;
      };
      value: string;
    };
  }) => void;
  value: string;
};

export const Chip: FC<ChipProps> = ({
  checked: propsChecked,
  excluded: propsExcluded,
  value,
  onChange,
  children,
  id,
}) => {
  const [checked, setChecked] = useState(propsChecked);
  const [excluded, setExcluded] = useState(propsExcluded);
  const { formatMessage } = useIntl();

  const handleInclude: MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    setChecked(true);
    setExcluded(false);
    const type = getDisplayedIcon({ checked: true, excluded: false });

    onChange({ target: { checked: true, value, dataset: { type } } });
  };

  const handleExclude: MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    setChecked(true);
    setExcluded(true);
    const type = getDisplayedIcon({ checked: true, excluded: true });

    onChange({ target: { checked: true, value, dataset: { type } } });
  };

  const handleReset: MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    setChecked(false);
    setExcluded(false);

    const type = getDisplayedIcon({ checked: false, excluded: false });

    onChange({ target: { checked: false, value, dataset: { type } } });
  };

  const renderActionsIcons = () => {
    if (checked && excluded) {
      return (
        <>
          <Tooltip placement="top" id="excludeTerm" message={formatMessage(messages.include)}>
            <span className={theme('actions__icon-container')}>
              <Icon className={theme('actions__icon')} type="plus" onClick={handleInclude} />
            </span>
          </Tooltip>
          <Tooltip placement="top" id="removeTerm" message={formatMessage(messages.reset)}>
            <span className={theme('actions__icon-container')}>
              <Icon
                className={theme('actions__icon', { with_margin: true })}
                type="cross"
                onClick={handleReset}
              />
            </span>
          </Tooltip>
        </>
      );
    }

    if (checked && !excluded) {
      return (
        <>
          <Tooltip placement="top" id="excludeTerm" message={formatMessage(messages.exclude)}>
            <span className={theme('actions__icon-container')}>
              <Icon className={theme('actions__icon')} type="ban" onClick={handleExclude} />
            </span>
          </Tooltip>
          <Tooltip placement="top" id="removeTerm" message={formatMessage(messages.remove)}>
            <span className={theme('actions__icon-container')}>
              <Icon
                className={theme('actions__icon', { with_margin: true })}
                type="cross"
                onClick={handleReset}
              />
            </span>
          </Tooltip>
        </>
      );
    }

    if (!checked && !excluded) {
      return (
        <>
          <Tooltip placement="top" id="excludeTerm" message={formatMessage(messages.include)}>
            <span className={theme('actions__icon-container')}>
              <Icon className={theme('actions__icon')} type="plus" onClick={handleInclude} />
            </span>
          </Tooltip>
          <Tooltip placement="top" id="excludeTerm" message={formatMessage(messages.exclude)}>
            <span className={theme('actions__icon-container')}>
              <Icon className={theme('actions__icon')} type="ban" onClick={handleExclude} />
            </span>
          </Tooltip>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    if (propsChecked !== checked || propsExcluded !== excluded) {
      setChecked(propsChecked);
      setExcluded(propsExcluded);
    }
  }, [propsChecked, propsExcluded]);

  return (
    <div
      className={theme('chip', { checked, excluded })}
      {...dataQa(id)}
      onClick={(e) => e.stopPropagation()}
    >
      <span>{children}</span>
      <div className={theme('actions')}>{renderActionsIcons()}</div>
    </div>
  );
};
