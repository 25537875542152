import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import thematize from 'lib/thematize';
import { COLORS } from 'components/Base/constants';
import styles from './AttentionLabel.scss';

const theme = thematize(styles);

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  color?: 'danger' | 'warning' | 'success' | 'orange';
  size?: 'm' | 'l';
  wide?: boolean;
}

const AttentionLabel: FC<Props> = ({
  children,
  color = COLORS.danger,
  wide = true,
  size = 'm',
  className = '',
  ...otherProps
}: Props) => (
  <p
    className={`${theme('attention-label', {
      color,
      wide,
      size,
    })} ${className}`}
    {...otherProps}
  >
    {children}
  </p>
);

export default AttentionLabel;
