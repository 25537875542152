import { ReactElement } from 'react';
import Image from 'react-loadable-image';
import { Link } from 'react-router-dom';
import { noop } from 'lodash';

import thematize from 'lib/thematize';
import Placeholder from 'components/PhotoPlaceholder';
import styles from './Avatar.scss';

const theme = thematize(styles);

export interface Props {
  id: number;
  initials: string;
  className?: string;
  linkDisabled?: boolean;
  linkPath?: string;
  linkTarget?: '_self' | '_blank';
  onClick?: () => void;
  pictureSources?: Array<string>;
  size?: 's' | 'm' | 'l';
}

const Avatar = ({
  initials,
  id,
  pictureSources = [],
  linkPath = '',
  onClick = noop,
  size = 'm',
  className = '',
  linkDisabled = false,
  linkTarget = '_blank',
}: Props): ReactElement => {
  const image = (
    <Image
      className={`${theme('photo', { size })} ${className}`}
      sources={pictureSources}
      placeholder={
        <Placeholder
          className={`${theme('photo', { size })} ${className}`}
          initials={initials}
          id={id}
        />
      }
    />
  );

  return linkPath && !linkDisabled ? (
    <Link
      to={linkPath}
      onClick={onClick}
      target={linkTarget}
      rel="noopener noreferrer"
      className={theme('link')}
    >
      {image}
    </Link>
  ) : (
    <span onClick={onClick}>{image}</span>
  );
};

export default Avatar;
