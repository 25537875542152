import { FC, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './Scrollbars.scss';

const theme = thematize(styles);

type ScrollbarsProps = {
  children: ReactNode;
  className?: string;
};

const Scrollbars: FC<ScrollbarsProps> = ({ children, className = '' }) => (
  <div className={`${theme('wrapper')} ${className}`}>{children}</div>
);

export default Scrollbars;
