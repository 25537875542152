import { ReactElement, ReactNode } from 'react';

import thematize from 'lib/thematize';
import styles from './Table.scss';

const theme = thematize(styles);

interface Props {
  children: ReactNode;
  className?: string;
  horizontalScroll?: boolean;
  verticalScroll?: boolean;
  wrapperClassName?: string;
}

const Table = ({
  children,
  className = '',
  wrapperClassName = '',
  horizontalScroll = false,
  verticalScroll = false,
}: Props): ReactElement => (
  <div className={`${theme('wrapper', { horizontalScroll, verticalScroll })} ${wrapperClassName}`}>
    <table className={`${theme('container')} ${className}`}>{children}</table>
  </div>
);

export { default as Sort } from './Sort';
export default Table;
