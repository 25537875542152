import { FC } from 'react';

import thematize from 'lib/thematize';
import styles from './InlineSpinner.scss';

const theme = thematize(styles);

type Props = {
  size?: 'm' | 's';
  spinnerClassName?: string;
  wrapperClassName?: string;
};

const InlineSpinner: FC<Props> = ({ size = 'm', spinnerClassName = '', wrapperClassName = '' }) => (
  <div className={`${theme('container', { size })} ${wrapperClassName}`}>
    <div className={`${theme('spinner')} ${spinnerClassName}`} />
  </div>
);

export default InlineSpinner;
